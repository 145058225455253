import React from "react"

const Service = props => {
    return (
        <section className="service-wrap">
            <div className="section-title-wrap">
                <h2 className="title">サービス</h2>
                <p className="sub">Services</p>
            </div>
            <div className="contents">
                <div className="content">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_icon01.svg" alt="Service Image 1"/>
                        <div className="title-wrap">
                            <h3 className="title">機器販売</h3>
                        </div>
                    </div>
                    <p className="description">ご要望に合う最適な機器を、複数のランドリー機器メーカーの中からご提案。専門業者による確実な機器設置・施工を実施します。</p>
                </div>
                <div className="content">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_icon02.svg" alt="Service Image 2"/>
                        <div className="title-wrap">
                            <h3 className="title">企画立案</h3>
                        </div>
                    </div>
                    <p className="description">商圏調査・物件紹介・収支計画などを企画、コンサルティング。クリーニング、カフェ併設などの複合案件も対応可能です。</p>
                </div>
                <div className="content">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_icon03.svg" alt="Service Image 3"/>
                        <div className="title-wrap">
                            <h3 className="title">設計デザイン</h3>
                        </div>
                    </div>
                    <p className="description">200店舗以上出店の知見を生かし、デザイン性とユーザビリティの高い店舗をプロデュース。ブランドデザインも提案可能です。</p>
                </div>
                <div className="content">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_icon04.svg" alt="Service Image 4"/>
                        <div className="title-wrap">
                            <h3 className="title">運営代行</h3>
                        </div>
                    </div>
                    <p className="description">コールセンター・清掃・機器メンテナンスなど日々の運営をOKULABが代行。オープン後も良好な利用環境を維持することができます。</p>
                </div>
            </div>
        </section>
    )
}
export default Service
