import React from "react"

const OurWorks = props => {
    return (
        <section className="works-wrap">
            <div className="section-title-wrap">
                <h2 className="title">事例</h2>
                <p className="sub">Our Works</p>
            </div>
            <div className="works">
                <div className="work">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_freddy-store01.jpg" alt="Freddy Image 1"/>
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_freddy-store02.jpg" alt="Freddy Image 2"/>
                    </div>
                    <div className="text-wrap">
                        <h3>フレディ レック・ウォッシュサロン トーキョー<span className="address">所在地：東京都目黒区</span></h3>
                        <p className="service-text">提供サービス：機器選定・販売・保守、店舗企画、洗濯コース企画、クリーニング・洗濯代行ソリューション提案</p>
                    </div>
                </div>
                <div className="work">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_shirofuwa-store01.jpg" alt="Shirofuwa Image 1"/>
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_shirofuwa-store02.jpg" alt="Shirofuwa Image 2"/>
                    </div>
                    <div className="text-wrap">
                        <h3>Shirofuwa Laundry<span className="address">所在地：東京都渋谷区</span></h3>
                        <p className="service-text">提供サービス：機器選定・販売・保守、店舗企画、デザイン、洗濯コース企画</p>
                    </div>
                </div>
                <div className="work">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_mutio-store01.jpg" alt="Mutio Image 1"/>
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_mutio-store02.jpg" alt="Mutio Image 2"/>
                    </div>
                    <div className="text-wrap">
                        <h3>Muito Bom!<span className="address">所在地：東京都北区</span></h3>
                        <p className="service-text">提供サービス：機器選定・販売・保守、物件選定、店舗企画、デザイン、洗濯コース企画、販促サポート</p>
                    </div>
                </div>
                <div className="work">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_idobata-store01.jpg" alt="Idobata Image 1"/>
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_idobata-store02.jpg" alt="Idobata Image 2"/>
                    </div>
                    <div className="text-wrap">
                        <h3>Idobata Laundry<span className="address">所在地：北海道帯広市</span></h3>
                        <p className="service-text">提供サービス：機器選定・販売・保守、物件選定、店舗企画、洗濯コース企画、販促サポート</p>
                    </div>
                </div>
                <div className="work">
                    <div className="image-wrap">
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_nozawa-store01.jpg" alt="Nozawa Image 1"/>
                        <img src="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_nozawa-store02.jpg" alt="Nozawa Image 2"/>
                    </div>
                    <div className="text-wrap">
                        <h3>NOZAWA Laundry<span className="address">所在地：東京都世田谷区</span></h3>
                        <p className="service-text">提供サービス：機器選定・販売・保守、物件選定、店舗企画、デザイン、洗濯コース企画、販促サポート</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default OurWorks
