import React from "react"
import {Link} from "gatsby";

const ContentFooter = props => {
    function formatDescription(description) {
        // (PC限定)文字列を改行コードで分割
        return description.split('\\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    }
    return (
        <section className="content-footer">
            <div className="description">
                {formatDescription(props.description)}
            </div>
            <Link to={props.href} className="contact_btn">
                {props.buttonText}
            </Link>
        </section>
    )
}
export default ContentFooter
