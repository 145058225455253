import React from "react"
import FaqRow from "./faqRow";

const Faq = props => {
    const data = JSON.parse(props.data);
    return (
        <section className="faq-wrap">
            <div className="contents">
                <div className="section-title-wrap">
                    <h2 className="title">よくある質問</h2>
                    <p className="sub">FAQ</p>
                </div>

                <div className="questions-wrap">
                    {data.map(({ q, a }) => (
                        <FaqRow q={q} a={a} key={q} />
                    ))}
                </div>
            </div>
        </section>
    )
}
export default Faq