import React, { useEffect } from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import * as FadeIn from "components/fadein"
import PageLead from "../../../components/pageLead";
import Faq from "../../../components/faq";
import ContentFooter from "../../../components/contentFooter";
import Service from "../../../components/startup/service";
import OurWorks from "../../../components/startup/ourWorks";


const OpeningSupportPage = () => {
    useEffect(() => {
    }, [])

    return (
        <Layout>
            <div className="opening-support-page">
                <PageLead sub="SERVICE"
                          title="コインランドリー出店支援"
                          description="Baluko Laundry Placeの出店・運営によって培ったナレッジで、\nオリジナルランドリーの出店を支援します。"
                          imageUrl="https://stgokulabweb.blob.core.windows.net/images/services/opening_support/sp_shop-produce.jpg"
                          linkUrl="/contact"
                />
                <div className="contents">
                    <FadeIn.Up>
                        <Service />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <OurWorks />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <Faq data='[
                    {
                        "q": "出店までの期間はどれくらいかかりますか？",
                        "a": "諸条件により異なりますが、目安期間は約1～2ヶ月です。"
                    },
                    {
                        "q": "機器だけ購入したいのですが可能ですか？",
                        "a": "はい、可能です。必要に応じて設計要件の相談、設置後のコース設定なども対応させていただきます。"
                    },
                    {
                        "q": "既存の事業の店舗の一部をコインランドリーに改装したいですが可能ですか？",
                        "a": "物件の用途地域などの要件確認からサポートさせていただきます。まずはお問い合わせください。"
                    },
                    {
                        "q": "出店にはどれくらい費用がかかりますか？",
                        "a": "物件条件などによって異なります。<br /><div style=color:black;font-weight:700;margin-top:10px;>モデルプランA　2,500万円～（税抜）</div><ul style=list-style:disc;margin-left:20px;><li>オーナー所有物件（10平米～）</li><li>設計・施工・ロゴデザイン・販促物デザイン</li></ul><div style=color:black;font-weight:700;margin-top:10px;>モデルプランB　5,000万円～（税抜）</div><ul style=list-style:disc;margin-left:20px;><li>建屋建築（建屋約100平米＋駐車場）</li><li>建屋を含む設計・施工（ロゴデザイン、販促物はオーナーにてデザイン作成）</li></ul>"
                    }
                    ]'
                        />
                    </FadeIn.Up>
                    <FadeIn.Up>
                        <ContentFooter
                            description="200店舗以上の実績を基にしたノウハウで、コインランドリー出店をサポートします。\nお気軽にお問い合わせください。"
                            buttonText="お問い合わせ"
                            href="/contact"
                        />
                    </FadeIn.Up>
                </div>
            </div>
        </Layout>
    )
}
export const Head = () => (
    <Seo
        title="ランドリー出店支援"
        description="あらゆるブランドのコインランドリーの出店をサポートいたします。コインランドリー経営を目指すオーナー様のニーズに合わせて、機器販売からブランド構築、販促支援まできめ細やかなサポートが可能です。"
        topFlag={true}
    />
)

export default OpeningSupportPage
