import React, {useState} from "react"
import ReactHtmlParser from "html-react-parser"

const FaqRow = props => {
    const q = props.q;
    const a = props.a;
    const [isOpen, setOpen] = useState(false);

    const handleOpenAnswer = (fn, state) => {
        fn(!state);
    }

    return (
        <div className="row">
            <span className="question" onClick={() => handleOpenAnswer(setOpen, isOpen)}>
                <span className="q">Q</span>
                <span className="question-text">{q}</span>
                <label className={`${isOpen ? "active" : ""} open-toggle-btn`}>
                </label>
            </span>
            <div className={`${isOpen ? "active" : ""} answer`}>
                <span className="a">A</span>
                <div className="answer-text">
                    {ReactHtmlParser(a)}
                </div>
            </div>
        </div>
    )
}
export default FaqRow